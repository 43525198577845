import cn from 'classnames'
import classNames from 'classnames'
import React from 'react'
import { motion, Variants } from 'framer-motion'
import type { Transition } from 'framer-motion'

const loadingContainerVariants = {
  start: {
    transition: {
      staggerChildren: 0.2,
    },
  },
  end: {
    transition: {
      staggerChildren: 0.2,
    },
  },
}

const loadingCircleVariants = {
  start: {
    y: '50%',
  },
  end: {
    y: '150%',
  },
}

const loadingCircleTransition: Transition = {
  duration: 0.5,
  repeatType: 'reverse',
  repeat: Infinity,
  ease: 'easeInOut',
}

const iconVariants: Variants = {
  hover: {
    x: '0.25em',

    transition: { type: 'spring', duration: 0.4 },
  },
}

type CustomButtonProps = {
  label: string
  isLoading: boolean
  className?: string
  icon?: any
  animateIcon?: boolean
}

const SubmitButton: React.FC<CustomButtonProps> = ({
  label,
  isLoading,
  className = '',
  icon,
  animateIcon = false,
}) => {
  return (
    <button
      disabled={isLoading}
      type="submit"
      className={classNames(
        'inline-flex items-center px-4 py-4 text-white font-body font-bold leading-none focus:bg-orange rounded focus:outline-none transition-all transition-all focus:ring-4 focus:ring-mustard-500',
        [
          {
            'border-orange bg-orange hover:bg-orange-800 hover:border-orange-800 focus:border-orange hover:shadow-400 justify-center ':
              !isLoading,
            'border-navy-light-100 bg-orange-400 cursor-not-allowed justify-between':
              isLoading,
          },
        ],
        className
      )}
    >
      <span>{label}</span>
      {icon && (
        <motion.span
          variants={iconVariants}
          className={cn('ml-1 w-5 h-5', animateIcon && 'mr-1')}
        >
          {icon}
        </motion.span>
      )}
      {isLoading && (
        <motion.div
          className="flex justify-around w-8 h-4"
          variants={loadingContainerVariants}
          initial="start"
          animate="end"
        >
          <motion.span
            className="block w-2 h-2 bg-white rounded"
            variants={loadingCircleVariants}
            transition={loadingCircleTransition}
          />
          <motion.span
            className="block w-2 h-2 bg-white rounded"
            variants={loadingCircleVariants}
            transition={loadingCircleTransition}
          />
          <motion.span
            className="block w-2 h-2 bg-white rounded"
            variants={loadingCircleVariants}
            transition={loadingCircleTransition}
          />
        </motion.div>
      )}
    </button>
  )
}
export default SubmitButton
