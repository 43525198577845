import { useForm } from 'react-hook-form'
import { useState } from 'react'
import { Container } from '@/components/utils'
import { Image } from '@/components/elements/Image'
import { Input } from '@/components/elements/Input'
import SubmitButton from '@/components/elements/Button/submit'
import { Link } from '@/components/elements/Link'
import { Checkbox } from '@/components/elements/Checkbox'
import { CheckboxGroup } from '@/components/elements/Checkbox/group'
import { isValidEmail } from '@/utils/isValidEmail'
import { useSubscribeToNewsletterMutationMutation } from '../../../types/types'
import { Alert } from '@/components/elements/Alert'

export interface NewsletterSectionProps {
  title: string
  subtitle: string
}

export const NewsletterSection: React.FC<NewsletterSectionProps> = ({
  title,
  subtitle,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    criteriaMode: 'all',
  })

  const [errorMessages, setErrorMessages] = useState('')

  const [subscribeToNewsletter, { loading: subscribing, data, error }] =
    useSubscribeToNewsletterMutationMutation({
      onError: ({ networkError, graphQLErrors }) => {
        if (networkError) {
          setErrorMessages('there has been a network error')
        }
        if (graphQLErrors) {
          setErrorMessages(graphQLErrors[0].message)
        }
      },
    })
  const submitRegistration = (formData) => {
    subscribeToNewsletter({
      variables: {
        email: formData.email,
      },
    }).then((res) => {
      if (!res.data) return
      reset()
    })
  }
  return (
    <section className="relative py-24 bg-navy-900">
      <Container className="relative z-10 flex flex-col items-start justify-between text-left text-white sm:flex-row">
        <div className="flex-shrink-0 sm:mr-4 sm:w-1/2">
          <h2 className="mb-4 text-left font-display text-2xl font-black leading-none sm:text-3xl">
            {title}
          </h2>
          <p className="text-navy-light-100 font-body text-sm sm:text-base">
            {subtitle}
          </p>
        </div>

        {data?.subscribeToNewsletter ? (
          <Alert
            className="mt-4 sm:mt-0"
            title="Gràcies per subscriure’t-hi"
            description="T'has subscrit correctament al butlletí!"
            type="success"
          />
        ) : (
          <form
            onSubmit={handleSubmit(submitRegistration)}
            className="mt-10 sm:mt-0"
          >
            <div className="flex flex-col text-white sm:flex-row">
              <Input
                className="mb-4 w-full sm:mb-0 sm:mr-2"
                id="email"
                name="email"
                label={null}
                validationRules={{
                  required: 'Empleneu aquest camp',
                  validate: isValidEmail,
                }}
                register={register}
                errors={errors}
                placeholder="Escriu el teu mail"
              />
              <SubmitButton
                isLoading={subscribing}
                label="Subscriure’m"
                className="sm:w-auto"
              />
            </div>
            <div>
              <CheckboxGroup errors={errors} name="acceptPrivacy">
                <Checkbox
                  name="acceptPrivacy"
                  register={register}
                  id="privacy-policy"
                  label="Accepto la política de privacitat"
                  description={<PrivacyPolicy />}
                  className="text-white"
                  validationRules={{
                    required: 'Empleneu aquest camp',
                  }}
                />
              </CheckboxGroup>
            </div>
            {error && (
              <Alert
                type="error"
                className="mt-6"
                title="Error en el formulari"
                description={errorMessages}
              />
            )}
          </form>
        )}
      </Container>
    </section>
  )
}

export const PrivacyPolicy = (): JSX.Element => {
  return (
    <ul className="sm:max-w-1/2 mt-1 text-white">
      <li className="inline">
        <strong>Responsable: </strong>
        Òmnium Cultural.
      </li>
      <li className="inline">
        <strong>Finalitats: </strong>
        gestionar la teva inscripció al butlletí per fer-te arribar informació
        del projecte i d’Òmnium Cultural.
      </li>
      <li className="inline">
        <strong>Drets: </strong>
        Accés, rectificació, supressió, oposició, limitació i portabilitat de
        dades.
      </li>
      <li className="inline">
        <strong>Més informació: </strong>
        Trobaràs informació addicional i més detallada a la{' '}
        <Link
          href="/politica-de-privacitat"
          className=""
          variant="no-variant"
          target="_blank"
        >
          Política de Privacitat.
        </Link>
      </li>
    </ul>
  )
}
