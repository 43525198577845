import { PageMeta } from 'src/pages'
import Head from 'next/head'
import { Header, HeaderProps } from './patterns/Header'
import { Footer, FooterProps } from './patterns/Footer'

export const GlobalStyles: React.FC = ({ children }) => (
  <div className="text-navy-900 antialiased">{children}</div>
)

export const Layout: React.FC<{
  meta: PageMeta
}> = ({ meta: { title, description }, children }) => {
  const header: HeaderProps = {
    pages: [
      { title: 'Activitats', url: '/#activitats' },
      { title: 'Qui som', url: '/#qui-som' },
      { title: 'FAQs', url: '/faqs' },
      { title: 'Contacte', url: '/contacte' },
    ],
  }

  const footer: FooterProps = {
    links: [
      { title: 'Contacte', url: '/contacte' },
      { title: 'Avís legal', url: '/avis-legal' },
      { title: 'Política de privacitat', url: '/politica-de-privacitat' },
      { title: 'Política de cookies', url: '/politica-de-cookies' },
      { title: 'Accessibilitat', url: '/accessibilitat' },
      { title: 'Admin', url: '/admin' },
    ],
    instagramUrl: 'https://www.instagram.com/omniumcultural/',
    facebookUrl: 'https://www.facebook.com/omniumcultural/',
    twitterUrl: 'https://twitter.com/omnium',
    telegramUrl: 'https://t.me/omnium',
    youtubeUrl: 'https://www.youtube.com/user/omniumcultural',
    phone: '+34 93 319 80 50',
    email: 'tandem@omnium.cat',
    address: {
      headquarters: "Seu Nacional d'Òmnium Cultural",
      street: 'Diputació, 276, pral.',
      city: '08009 Barcelona',
    },
    copyright: '© 2021 Tandem. Tots els drets reservats.',
    signupUrl:
      'https://fes-te-soci.omnium.cat/?utm_source=tandemcultural.cat&utm_medium=referral&utm_campaign=tandem-footer',
    signupText: "Fes-te soci d'Òmnium",
  }

  return (
    <GlobalStyles>
      <div className="flex flex-col min-h-full">
        <Head>
          <title>{title}</title>
          <meta name="description" content={description} />
        </Head>

        <Header {...header} />
        <main className="flex-1">{children}</main>
        <Footer {...footer} />
      </div>
    </GlobalStyles>
  )
}
